<template>
  <div>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-10" @close="emit('close')">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
              >
                <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500" @click="emit('close')">
                    <icon name="heroicons:x-mark-solid" class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="px-4 pt-5 pb-4 sm:p-6">
                  <div>
                    <div class="flex justify-center mt-4">
                      <h2 class="text-sm md:text-2xl">
                        {{ `Food Pantry` }}: <b>{{ foodPantry?.name }}</b>
                      </h2>
                    </div>
                    <div class="flex justify-center">
                      <canvas ref="canvas"></canvas>
                    </div>
                    <div class="border rounded-md py-2 px-2 relative mt-4">
                      {{ foodPantry?.url }}
                      <button
                        class="absolute right-1 border px-1 border-[#0D2951] bg-[#0D2951] text-white rounded-md"
                        @click="copyToClipBoard"
                        aria-hidden="true"
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
                <div class="border-t border-gray-500 text-center py-6">
                  <button
                    class="font-proxima text-white text-sm font-semibold px-6 py-2 rounded-md capitalize bg-red-500 border border-gray-600"
                    @click="emit('close')"
                  >
                    {{ $t('Close') }}
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { storeToRefs } from 'pinia';
import QRCode from 'qrcode';
import { generateFoodPantryUrl } from '@tsamm-v2/admin-dashboard/utils/helpers';

const emit = defineEmits(['close']);
const props = defineProps<{
  open: boolean;
}>();

const foodPantryStore = useFoodPantryStore();
const { foodPantry } = storeToRefs(foodPantryStore);

console.log(foodPantry.value)

const canvas = ref();
const generateQrCode = () => {
  if (canvas.value) {
    QRCode.toCanvas(
      canvas.value,
      generateFoodPantryUrl(foodPantry?.value?.url),
      {
        width: 210,
        margin: 0,
      },
      (error) => {
        if (error) console.error(error);
      }
    );
  }
};

onMounted(() => {
  setTimeout(() => {
    generateQrCode();
  }, 300);
});

const copyToClipBoard = () => {
  navigator.clipboard.writeText(foodPantry.value?.url ?? '').then(function () {
    toastSuccess('', 'Link copied successfully');
  });
};
</script>
